import React from "react";
import { useTranslation } from "react-i18next";

import { formatDateTimeUTC } from "@utils/formatDateTime";
import { numberWithSpaces } from "@utils/numberWithSpaces";

import { ReactComponent as FinishedStakingIcon } from "@images/icons/icon-staking-finished.svg";

import StakingTimeline from "@components/common/StakingTimeline/StakingTimeline";
import CardInfoItem from "@components/common/CardInfoItem/CardInfoItem";
import Button from "@components/toolkit/Button/Button";

import { StakingCardProps } from "../../StakingCard";
import "./FinishedStageCard.scss";

const FinishedStageCard = ({ staking, onModalOpen }: StakingCardProps) => {
  const { stakingName, duration, status, stages, earningPercent } = staking;

  const { t } = useTranslation(["common"]);

  const stakingDuration =
    duration.inMonths > 0 ? `${duration.inMonths} ${t("common:months")}` : `${duration.inDays} ${t("common:days")}`;

  return (
    <div className="finished-stage-card">
      <div className="card-header">
        <h2>{stakingName}</h2>
        <FinishedStakingIcon />
      </div>

      <div className="card-timeline">
        <StakingTimeline status={status} stages={stages} />
      </div>

      <div className="card-summary">
        <div className="staking-info">
          <CardInfoItem label={t("common:apr")} value={`${numberWithSpaces(earningPercent.apr)}%`} />
          <CardInfoItem label={t("common:duration")} value={stakingDuration} />
          <CardInfoItem label={t("common:endOfStaking")} value={formatDateTimeUTC(stages.earnStageTo, "DD MMM YYYY")} />
        </div>

        <div className="staking-actions">
          <Button variant="secondary" onClick={onModalOpen}>
            {t("common:details")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FinishedStageCard;
