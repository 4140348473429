import React from "react";
import { useTranslation } from "react-i18next";

import { formatDateTimeUTC } from "@utils/formatDateTime";
import { numberWithSpaces } from "@utils/numberWithSpaces";

import { ReactComponent as JoinBadge } from "@images/layouts/badge-join.svg";

import Button from "@components/toolkit/Button/Button";
import CountdownTimer from "@components/common/CountdownTimer/CountdownTimer";
import StakingTimeline from "@components/common/StakingTimeline/StakingTimeline";
import CardInfoItem from "@components/common/CardInfoItem/CardInfoItem";
import Group from "@components/arrangement/Group/Group";

import { StakingCardProps } from "../../StakingCard";
import "./SubscribeStageCard.scss";

const SubscribeStageCard = ({ staking, onModalOpen }: StakingCardProps) => {
  const { stakingName, duration, status, stages, earningPercent, currentTotalDeposit, maxTotalStake } = staking;

  const { t } = useTranslation(["common"]);

  const stakingDuration =
    duration.inMonths > 0 ? `${duration.inMonths} ${t("common:months")}` : `${duration.inDays} ${t("common:days")}`;
  const percentageOfDeposit =
    Math.round((currentTotalDeposit.number / maxTotalStake.number) * 100 * 100 + Number.EPSILON) / 100;

  return (
    <div className="subscribe-stage-card">
      <JoinBadge className="card-badge" />

      <div className="card-main">
        <div className="card-header">
          <h2>{stakingName}</h2>

          <Group gap={30}>
            <p className="timer-text">{t("common:stakingStartsIn")}</p>
            <CountdownTimer variant="small" targetDate={stages.subscribeStageTo} />
          </Group>
        </div>

        <div className="card-timeline">
          <StakingTimeline status={status} stages={stages} />
        </div>

        <div className="card-summary">
          <div className="staking-info">
            <CardInfoItem label={t("common:apr")} value={`${numberWithSpaces(earningPercent.apr)}%`} />
            <CardInfoItem label={t("common:duration")} value={stakingDuration} />
            <CardInfoItem
              label={t("common:endOfStaking")}
              value={formatDateTimeUTC(stages.earnStageTo, "DD MMM YYYY")}
            />
            <CardInfoItem label={t("common:percentageOfTheDeposit")} value={`${percentageOfDeposit}%`} />
          </div>

          <div className="staking-actions">
            <Button variant="secondary" onClick={onModalOpen}>
              {t("common:details")}
            </Button>
            <Button onClick={onModalOpen}>{t("common:joinNow")}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeStageCard;
