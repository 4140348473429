import { ethers } from "ethers";

import { AppConfig } from "@config/config";
import { useWallet } from "@contexts/WalletProvider";
import { useStakingList } from "@contexts/StakingListContext/StakingList.context";
import * as EthHooks from "@services/eth/EthHooks";

import DevModeGuard from "@guards/DevModeGuard/DevModeGuard";
import Button from "@components/toolkit/Button/Button";

import "./Debug.scss";

const Debug = () => {
  const { metamaskStatus, walletAddress, isOnCorrectNetwork, tokenBalance, connectMetamask, switchNetwork } =
    useWallet();
  const { stakingList } = useStakingList();

  const network = EthHooks.useNetwork();

  const { allowance, refetchAllowance } = EthHooks.useCurrentAllowance(walletAddress, stakingList?.[0]?.address);
  const { sendApproveTx } = EthHooks.useApproveTx(stakingList?.[0]?.address);
  // const { sendStakeTx } = EthHooks.useStakeTx();

  const { sendUnstakeTx } = EthHooks.useUnstakeTx(0);
  const { claim } = EthHooks.useClaimTx(0);

  const handleApprove = async () => {
    await sendApproveTx(ethers.parseUnits("10000", 8).toString());
    await refetchAllowance();
  };

  const handleStake = async () => {
    // await sendStakeTx(0, ethers.parseUnits("10000", 8));
    // await refetchStakedBalance();
  };

  const handleUnstake = async () => {
    await sendUnstakeTx();
    // await refetchStakedBalance();
  };

  return (
    <div className="debug-page">
      <h1 className="text-center">Debug page</h1>

      <Button onClick={connectMetamask}>connect</Button>
      <p>connection status: {metamaskStatus}</p>
      <p>account address: {walletAddress}</p>
      <p>
        network: {network?.name} {network?.chainId.toString()}
      </p>
      <span>is on correct network: {isOnCorrectNetwork ? "true" : "false"}</span>
      <span>
        {!isOnCorrectNetwork && <button onClick={() => switchNetwork(AppConfig.EthChainId)}> switch network</button>}
      </span>
      <p>token balance: {tokenBalance?.number}</p>
      <p className="bold">Staking instances:</p>
      {stakingList?.map?.((inst) => (
        <p key={inst.address}>{inst.address}</p>
      ))}
      <p className="bold">Staking #1 allowance: {allowance ? ethers.formatUnits(allowance, 8) : 0}</p>
      <Button onClick={handleApprove}>send approve tx</Button>
      {/* <p>Staking #1 staked balance: {stakedBalance}</p> */}
      <Button onClick={handleStake}>send stake tx</Button>
      <Button onClick={handleUnstake}>send unstake tx</Button>
      <Button onClick={claim}>claim</Button>
      {/* {stakingList?.[0] && <StakingModal stakingInstance={stakingList[0]} />} */}
    </div>
  );
};

export default (
  <DevModeGuard>
    <Debug />
  </DevModeGuard>
);
