import React from "react";
import { useTranslation } from "react-i18next";

import { AppConfig } from "@config/config";
import { StakingData } from "@src/types/Staking.types";
import { numberWithSpaces } from "@utils/numberWithSpaces";
import { formatDateTime } from "@utils/formatDateTime";

import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Link from "@components/toolkit/Link/Link";
import StakingProgress from "../StakingProgress/StakingProgress";

import "./StakingDashboardCard.scss";

interface CardProps {
  staking: StakingData;
}
const StakingDashboardCard = ({ staking }: CardProps) => {
  const {
    address,
    status,
    stages,
    earningPercent,
    currentTotalDeposit,
    maxTotalStake,
    maxUserStake,
    earningsQuota,
    unusedQuota,
    duration,
    stakingName
  } = staking;

  const { t } = useTranslation(["common"]);

  const contractExplorerUrl = AppConfig.EthExplorerUrl + "/address/" + address;

  return (
    <div className="staking-dashboard-card">
      <h3 className="medium">{stakingName}</h3>

      <StakingProgress
        currentTotalDeposit={currentTotalDeposit.number}
        maxTotalStake={maxTotalStake.number}
        className="mt-6"
      />

      <Group gap={4} className="mt-5">
        <p className="p3 bold">{t("common:contract")}:</p>

        <Link href={contractExplorerUrl} target="_blank" className="p3">
          {address}
        </Link>
      </Group>
      <Group gap={24} alignItems="flex-start">
        <Stack>
          <p className="p3">
            <span className="bold">{t("common:stakingDuration")}: </span>
            {duration.inDays} {t("common:days")}
          </p>

          <p className="p3">
            <span className="bold">{t("common:apr")}: </span>
            {earningPercent.apr}%
          </p>

          <p className="p3">
            <span className="bold">{t("common:totalStakingPool")}: </span>
            {numberWithSpaces(maxTotalStake.number)}
          </p>

          <p className="p3">
            <span className="bold">{t("common:userMaxDeposit")}: </span>
            {numberWithSpaces(maxUserStake.number)}
          </p>
        </Stack>

        <Stack>
          <p className="p3">
            <span className="bold">{t("common:currentTotalDeposit")}: </span>
            {numberWithSpaces(currentTotalDeposit.number)}
          </p>

          <p className="p3">
            <span className="bold">{t("common:earningsQuota")}: </span>
            {numberWithSpaces(earningsQuota.number)}
          </p>

          <p className="p3">
            <span className="bold">{t("common:unusedQuota")}: </span>
            {numberWithSpaces(unusedQuota.number)}
          </p>
        </Stack>

        <Stack>
          <p className="p3">
            <span className="bold">{t("common:status")}: </span>
            {status}
          </p>

          <p className="p3">
            <span className="bold">{t("common:subscribeFrom")}: </span>
            {formatDateTime(stages.subscribeStageFrom)}
          </p>

          <p className="p3">
            <span className="bold">{t("common:subscribeTo")}: </span>
            {formatDateTime(stages.subscribeStageTo)}
          </p>

          <p className="p3">
            <span className="bold">{t("common:earnStageTo")}: </span>
            {formatDateTime(stages.earnStageTo)}
          </p>

          <p className="p3">
            <span className="bold">{t("common:claimStageTo")}: </span>
            {formatDateTime(stages.claimStageTo)}
          </p>
        </Stack>
      </Group>
    </div>
  );
};

export default StakingDashboardCard;
