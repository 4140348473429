import { StakingInstanceDataStructOutput } from "staking-contract/lib/contracts/StakingAggregatorV2";

import { StakingData } from "../../../types/Staking.types";

import { convertMiliseconds } from "@utils/parsers/convertMiliseconds";
import { getCurrentStakingStatus } from "./getCurrentStakingStatus";
import { parseEarningPercentToPercent } from "./parseEarningPercentToPercent";
import { parseEarningPercentToAPR } from "./parseEarningPercentToAPR";
import { parseNumericValue } from "../../../services/eth/utils/parseNumericValue";
import { getStakingSetupData } from "@config/stakingSetup";

export const parseStakingData = (staking: StakingInstanceDataStructOutput): StakingData => {
  const { addr, data } = staking;
  const {
    aggregator,
    token,
    subscribeStageFrom,
    subscribeStageTo,
    earnStageTo,
    claimStageTo,
    currentTotalDeposit,
    maxTotalStake,
    maxUserStake,
    earningsQuota,
    earningPercent,
    unusedQuota
  } = data;

  const duration = Number((BigInt(earnStageTo) - BigInt(subscribeStageTo)) * BigInt(1000));
  const { contractIndex, stakingName, termsPdf } = getStakingSetupData(addr);

  return {
    contractIndex,
    stakingName,
    termsPdf,
    address: addr,
    aggregatorAddress: aggregator,
    tokenAddress: token,
    status: getCurrentStakingStatus(data),
    duration: {
      inMonths: convertMiliseconds(duration, "months"),
      inDays: convertMiliseconds(duration, "days")
    },
    stages: {
      subscribeStageFrom: Number(subscribeStageFrom) * 1000, //[ms]
      subscribeStageTo: Number(subscribeStageTo) * 1000, //[ms]
      earnStageTo: Number(earnStageTo) * 1000, //[ms]
      claimStageTo: Number(claimStageTo) * 1000 //[ms]
    },
    currentTotalDeposit: parseNumericValue(currentTotalDeposit),
    maxTotalStake: parseNumericValue(maxTotalStake),
    maxUserStake: parseNumericValue(maxUserStake),
    unusedQuota: parseNumericValue(unusedQuota),
    earningsQuota: parseNumericValue(earningsQuota),
    earningPercent: {
      bigint: earningPercent,
      percent: parseEarningPercentToPercent(earningPercent),
      apr: parseEarningPercentToAPR(Number(subscribeStageTo) * 1000, Number(earnStageTo) * 1000, earningPercent)
    }
  };
};
