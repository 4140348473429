import React from "react";

import { StakingData } from "@src/types/Staking.types";
import { useUserBalances } from "@contexts/UserBalances.context";

import PendingLaunchCard from "./stages/PendingLaunchCard/PendingLaunchCard";
import SubscribeStageCard from "./stages/SubscribeStageCard/SubscribeStageCard";
import SubscribeAssignedCard from "./stages/SubscribeAssignedCard/SubscribeAssignedCard";
import EarnStageCard from "./stages/EarnStageCard/EarnStageCard";
import EarnAssignedCard from "./stages/EarnAssignedCard/EarnAssignedCard";
import ClaimStageCard from "./stages/ClaimStageCard/ClaimStageCard";
import ClaimAssignedCard from "./stages/ClaimAssignedCard/ClaimAssignedCard";
import FinishedStageCard from "./stages/FinishedStageCard/FinishedStageCard";
import FinishedAssignedCard from "./stages/FinishedAssignedCard/FinishedAssignedCard";

export interface StakingCardProps {
  staking: StakingData;
  onModalOpen: () => void;
}

const StakingCard = ({ staking, onModalOpen }: StakingCardProps) => {
  const { contractIndex, status } = staking;

  const { getStakingBalanceByIndex } = useUserBalances();

  const userBalance = getStakingBalanceByIndex(contractIndex);
  const isAssigned = userBalance && userBalance.max.bigint > 0n;

  const getCardComponent = () => {
    switch (status) {
      case "pending":
        return PendingLaunchCard;

      case "subscribe":
        if (isAssigned) return SubscribeAssignedCard;
        return SubscribeStageCard;

      case "earn":
        if (isAssigned) return EarnAssignedCard;
        return EarnStageCard;

      case "claim":
        if (isAssigned) return ClaimAssignedCard;
        return ClaimStageCard;

      case "finished":
        if (isAssigned) return FinishedAssignedCard;
        return FinishedStageCard;

      default:
        return PendingLaunchCard;
    }
  };

  const CardComponent = getCardComponent();

  return <CardComponent staking={staking} onModalOpen={onModalOpen} />;
};

export default StakingCard;
