/* eslint-disable no-console */

import { useMetaMask } from "metamask-react";
import { OldToken__factory, Staking__factory, StakingAggregatorV2__factory } from "staking-contract";
import { ErrorDecoder } from "ethers-decode-error";

import { AppConfig } from "@config/config";
import { BlockchainAddress } from "@src/types/Blockchain.types";

import { usePromise } from "@hooks/usePromise";

import { JsonRpcProvider, Web3Provider } from "./EthProvider";
import { GuessTxHash } from "./utils/guessTxHash";

export const ETH_TOKEN_CONTRACT = OldToken__factory.connect(AppConfig.EthTokenAddress, JsonRpcProvider);
export const ETH_AGGREGATOR_CONTRACT = StakingAggregatorV2__factory.connect(
  AppConfig.EthAggregatorAddress,
  JsonRpcProvider
);

const chainNotValidatedError = () => {
  console.error("chain not validated");
  throw new Error("chain not validated");
};

const throwTxError = async (e: any, guess?: GuessTxHash) => {
  // 32d073ef

  const decoder = ErrorDecoder.create([
    OldToken__factory.abi,
    StakingAggregatorV2__factory.abi,
    Staking__factory.abi
  ] as any);

  const decoded = await decoder.decode(e).catch(() => null);

  console.error(e);
  console.error("decoded", decoded);

  let txHash: string | null = null;

  if (guess) {
    txHash = await guess.waitForDeposit();

    guess.destroy();

    if (txHash) {
      return { guessedTxHash: txHash };
    }
  }

  throw new Error("tx error");
};

const getMetamask = async () => {
  if (!Web3Provider) throw new Error("no metamask");
  return await Web3Provider.getSigner();
};

// # ============================================================
// # CHAIN
// # ============================================================

export const useChainValidated = () => {
  const { account, chainId } = useMetaMask();

  const validateChain = async () => {
    const network = await JsonRpcProvider.getNetwork();

    return (
      !!account &&
      Number(chainId) === Number(AppConfig.EthChainId) &&
      Number(network.chainId) === AppConfig.EthNetworkConfig.chainId.num
    );
  };

  const { resolved } = usePromise(validateChain, !!chainId && !!account);

  return resolved;
};

export const useFeeData = () => {
  const getFeeData = async () => await JsonRpcProvider.getFeeData();

  return usePromise(getFeeData, true);
};

export const useNetwork = () => {
  try {
    return JsonRpcProvider._network;
  } catch {
    return;
  }
};

export const useCurrentAllowance = (accountAddress: string | null, stakingAddress?: BlockchainAddress) => {
  const validated = useChainValidated();
  const getCurrentAllowance = async () => await ETH_TOKEN_CONTRACT.allowance(accountAddress!, stakingAddress!);

  const isInitCallReady = validated! && !!stakingAddress && !!accountAddress;
  const { resolved: allowance, call: refetchAllowance } = usePromise(getCurrentAllowance, isInitCallReady);

  return { allowance, refetchAllowance };
};

export const useApproveTx = (stakingAddress?: BlockchainAddress) => {
  const validated = useChainValidated();

  const approve = async (value: string) => {
    if (!validated) chainNotValidatedError();

    const signer = await getMetamask();
    const tx = (await ETH_TOKEN_CONTRACT.connect(signer).approve(stakingAddress!, value).catch(throwTxError)) as any;

    await tx.wait(AppConfig.EthWaitBlocks);
    return tx;
  };

  return { sendApproveTx: approve };
};

// # ============================================================
// # CONTRACT WRITE
// # ============================================================

export const useStakeTx = () => {
  const validated = useChainValidated();

  const sendStakeTx = async (stakingIndex: number | null, value: bigint, stakingAddress: BlockchainAddress) => {
    if (!validated) chainNotValidatedError();

    const signer = await getMetamask();

    const guess = new GuessTxHash({
      provider: JsonRpcProvider,
      sender: signer.address,
      stakingAddress: stakingAddress!,
      value,
      expectedNonce: await JsonRpcProvider.getTransactionCount(signer.address)
    });

    const tx = (await ETH_AGGREGATOR_CONTRACT.connect(signer)
      .increaseDeposit(BigInt(stakingIndex!), value)
      .catch((err) => throwTxError(err, guess))) as any;

    if (tx?.guessedTxHash) return; //guessing worked -> don't display error or wait for tx

    await tx.wait(AppConfig.EthWaitBlocks);
  };

  return { sendStakeTx };
};

export const useUnstakeTx = (stakingIndex: number | null) => {
  const validated = useChainValidated();

  const sendUnstakeTx = async () => {
    if (!validated) chainNotValidatedError();

    const signer = await getMetamask();
    const tx = (await ETH_AGGREGATOR_CONTRACT.connect(signer)
      .withdrawDeposit(stakingIndex!)
      .catch(throwTxError)) as any;

    await tx.wait(AppConfig.EthWaitBlocks);
  };

  return { sendUnstakeTx };
};

export const useClaimTx = (stakingIndex: number | null) => {
  const validated = useChainValidated();

  const claim = async () => {
    if (!validated) chainNotValidatedError();

    const signer = await getMetamask();
    const tx = (await ETH_AGGREGATOR_CONTRACT.connect(signer).claim(stakingIndex!).catch(throwTxError)) as any;

    await tx.wait(AppConfig.EthWaitBlocks);
  };

  return { claim };
};
