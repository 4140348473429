type Staking = { contractIndex: number; addr: string; stakingName: string; termsPdf: string };

export const StakingSetup: Staking[] = [
  {
    contractIndex: 0,
    addr: "0x6d6582d271FDE7b8f4D3fb48E7dfd75237689703",
    stakingName: "Staking 19%",
    termsPdf: "/pdfs/staking_terms_180D.pdf"
  },
  {
    contractIndex: 1,
    addr: "0x6Aea54b735c9477B287C11933845c1852Bce2caA",
    stakingName: "Staking 21%",
    termsPdf: "/pdfs/staking_terms_270D.pdf"
  },

  // deployed at 24.03.2025
  {
    contractIndex: 3, // Here we skipped one index because an invalid staking contract was created in the aggregator with index 2 (addr: 0xE8720fE61791D97368beFda483254A90adeF589c)
    addr: "0xABe0983d46C4eA2965592cAB700A067eBBD0c745",
    stakingName: "Staking II 21%",
    termsPdf: "/pdfs/staking_terms_II_270D_24_03_2025.pdf"
  },
  {
    contractIndex: 4,
    addr: "0xfAF1a7c4277F586b94d1Ee03dB8E12991A1aBD70",
    stakingName: "Staking II 19%",
    termsPdf: "/pdfs/staking_terms_II_180D_24_03_2025.pdf"
  }
];

export const getStakingSetupData = (addr: string) => {
  const stakingSetupData = StakingSetup.find((staking) => staking.addr === addr);

  if (!stakingSetupData) {
    console.error(`StakingSetup data not found for the given address: ${addr}`);
    throw new Error(`StakingSetup data not found for the given address: ${addr}`);
  }

  return stakingSetupData;
};
