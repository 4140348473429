import React, { useCallback, useEffect, useState } from "react";
import { StakingInstanceDataStructOutput } from "staking-contract/lib/contracts/StakingAggregatorV2";

import { AppConfig } from "@config/config";
import { IChildren } from "@src/types/IChildren.types";
import { BlockchainAddress } from "@src/types/Blockchain.types";
import { StakingData } from "@src/types/Staking.types";
import { ETH_AGGREGATOR_CONTRACT } from "@services/eth/EthHooks";
import { deepEqual } from "@utils/equals/deepEqual";

import { parseStakingData } from "./utils/parseStakingData";
import { getCurrentStakingStatus } from "./utils/getCurrentStakingStatus";

type FetchStatus = "loading" | "success" | "error";

interface ContextValue {
  stakingList: StakingData[] | null;
  fetchStatus: FetchStatus;
  fetchStakingList: (loader?: boolean) => Promise<void>;
  getStakingData: (stakingAddress: BlockchainAddress) => StakingData | undefined;
  updateStakingData: (stakingAddress: BlockchainAddress, updatedData: Partial<StakingData>) => void;
}

const StakingListContext = React.createContext(null as any);

export const StakingListProvider = ({ children }: IChildren) => {
  const [stakingList, setStakingList] = useState<StakingData[] | null>(null);
  const [rawStakingList, setRawStakingList] = useState<StakingInstanceDataStructOutput[] | null>(null);
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>("loading");

  const _getStakingInstances = async () => await ETH_AGGREGATOR_CONTRACT.getInstances();

  useEffect(() => {
    fetchStakingList(true);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(fetchStakingList, AppConfig.UpdateStakingListInterval);

    return () => clearInterval(intervalId);
  }, [rawStakingList]);

  useEffect(() => {
    const interval = setInterval(() => {
      _updateStakingsStatuses();
    }, 1000);

    return () => clearInterval(interval);
  }, [stakingList]);

  const fetchStakingList = useCallback(
    async (loader = false) => {
      try {
        if (loader) setFetchStatus("loading");

        const res = await _getStakingInstances();

        const hiddenContractAddresses = AppConfig.HiddenContractAddresses;
        const filteredRes = res.filter((staking) => !hiddenContractAddresses.includes(staking.addr));

        if (filteredRes) {
          if (!rawStakingList) {
            setRawStakingList(filteredRes);
          }

          if (!stakingList) {
            const parsedStakingList = filteredRes.map(parseStakingData);

            setStakingList(parsedStakingList);
          } else {
            const isEqual = deepEqual(rawStakingList, filteredRes);

            if (!isEqual) {
              const parsedStakingList = filteredRes?.map(parseStakingData);

              setRawStakingList(filteredRes);
              setStakingList(parsedStakingList);
            }
          }
        }

        setFetchStatus("success");
      } catch (error) {
        setFetchStatus("error");
      }
    },
    [rawStakingList]
  );

  const _updateStakingsStatuses = () => {
    stakingList?.forEach?.((staking, index) => {
      const rawStakingData = rawStakingList?.find((instance) => instance.addr === staking.address);
      if (!rawStakingData) return;

      const currentStatus = getCurrentStakingStatus(rawStakingData.data);

      if (staking.status !== currentStatus) {
        const updatedStaking: StakingData = { ...staking, status: currentStatus };

        const updatedStakingList = [...stakingList.slice(0, index), updatedStaking, ...stakingList.slice(index + 1)];
        return setStakingList(updatedStakingList);
      }
    });
  };

  const getStakingData = useCallback(
    (stakingAddress: BlockchainAddress) => {
      return stakingList?.filter((item) => item.address === stakingAddress)[0];
    },
    [stakingList]
  );

  const updateStakingData = (stakingAddress: BlockchainAddress, updatedData: Partial<StakingData>) => {
    const foundStaking = stakingList?.find((staking) => staking.address === stakingAddress);
    if (!foundStaking) return;

    setStakingList((prevState) => {
      if (!prevState) return null;

      return prevState.map((staking) =>
        staking.address === stakingAddress ? { ...staking, ...updatedData } : staking
      );
    });
  };

  const contextValue: ContextValue = {
    stakingList,
    fetchStatus,
    fetchStakingList,
    getStakingData,
    updateStakingData
  };

  return <StakingListContext.Provider value={contextValue}>{children}</StakingListContext.Provider>;
};

export const useStakingList = (): ContextValue => {
  const context = React.useContext(StakingListContext);

  if (context === undefined) {
    throw new Error("useStakingList must be used within an StakingListProvider");
  }

  return context;
};
