import React from "react";
import Lottie from "react-lottie-player";
import { useTranslation } from "react-i18next";

import { AppConfig } from "@config/config";
import { formatDateTimeUTC } from "@utils/formatDateTime";
import { numberWithSpaces } from "@utils/numberWithSpaces";
import { calculateEarnings } from "@services/eth/utils/calculateEarnings";
import { useUserBalances } from "@contexts/UserBalances.context";

import { ReactComponent as RewardIcon } from "@images/icons/icon-reward.svg";

import StakingSpinnerAnim from "@animations/staking-spinner-anim.json";
import StakingTimeline from "@components/common/StakingTimeline/StakingTimeline";
import CardInfoItem from "@components/common/CardInfoItem/CardInfoItem";
import Button from "@components/toolkit/Button/Button";

import { StakingCardProps } from "../../StakingCard";
import "./EarnAssignedCard.scss";

const EarnAssignedCard = ({ staking, onModalOpen }: StakingCardProps) => {
  const { contractIndex, stakingName, duration, status, stages, earningPercent } = staking;

  const { getStakingBalanceByIndex } = useUserBalances();
  const { t } = useTranslation(["common"]);

  const stakingDuration =
    duration.inMonths > 0 ? `${duration.inMonths} ${t("common:months")}` : `${duration.inDays} ${t("common:days")}`;

  const userBalance = getStakingBalanceByIndex(contractIndex);
  const earnings = calculateEarnings(userBalance?.max.bigint || 0n, earningPercent.bigint);
  const stakingAmount = userBalance?.max.number || 0;

  return (
    <div className="earn-assigned-card">
      <div className="card-main">
        <div className="main-triangle" />

        <div className="card-header">
          <h2>{stakingName}</h2>

          <Lottie
            loop
            play
            speed={1.3}
            animationData={StakingSpinnerAnim}
            style={{ display: "flex", alignItems: "center", width: "48px", height: "48px" }}
          />
        </div>

        <div className="card-timeline">
          <StakingTimeline status={status} stages={stages} />
        </div>

        <div className="card-summary">
          <div className="staking-info">
            <CardInfoItem label={t("common:apr")} value={`${numberWithSpaces(earningPercent.apr)}%`} />
            <CardInfoItem label={t("common:duration")} value={stakingDuration} />
            <CardInfoItem
              label={t("common:endOfStaking")}
              value={formatDateTimeUTC(stages.earnStageTo, "DD MMM YYYY")}
            />
            <CardInfoItem
              label={t("common:yourStakingAmount")}
              value={`${numberWithSpaces(stakingAmount)} ${AppConfig.EthTokenSymbol}`}
            />
          </div>

          <div className="staking-actions">
            <Button variant="secondary" onClick={onModalOpen}>
              {t("common:details")}
            </Button>
          </div>
        </div>
      </div>

      <div className="card-rewards">
        <div className="rewards-content">
          <RewardIcon />

          <p className="p1 mt-6">{t("common:yourEarnings")}</p>
          <p className="rewards-text">
            {numberWithSpaces(earnings.number)} {AppConfig.EthTokenSymbol}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EarnAssignedCard;
