import { get } from "env-var";
import { ethNetworkConfig } from "./ethNetworkConfig";

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const APP_VERSION = require("../../package.json").version;

export class AppConfig {
  // App ==================================================================================
  static readonly AppStage = get("REACT_APP_STAGE").asEnum(["prod", "dev"]);
  static readonly UpdateStakingListInterval = get("REACT_APP_UPDATE_STAKING_LIST_INTERVAL")
    .default(10000)
    .asIntPositive(); //[ms]
  static readonly UpdateBalanceInterval = get("REACT_APP_UPDATE_BALANCE_INTERVAL").default(10000).asIntPositive(); //[ms]

  // Blockchain ===========================================================================
  static readonly EthChainId = get("REACT_APP_ETH_CHAIN_ID").default("11155111").asEnum(["1", "11155111"]);
  static readonly EthNetworkConfig = ethNetworkConfig[this.EthChainId];

  static readonly EthRPCProviderUrl = get("REACT_APP_ETH_RPC_PROVIDER_URL").required().asUrlString();
  static readonly EthExplorerUrl = get("REACT_APP_ETH_EXPLORER_URL").default("https://etherscan.io/").asUrlString();
  static readonly EthAggregatorAddress = get("REACT_APP_ETH_AGGREGATOR_ADDRESS").required().asString();

  static readonly EthTokenAddress = get("REACT_APP_ETH_TOKEN_ADDRESS").required().asString();
  static readonly EthTokenDecimals = get("REACT_APP_ETH_TOKEN_DECIMALS").default(8).asIntPositive();
  static readonly EthTokenSymbol = "$SKEY";
  static readonly EthContractTokenSymbol = get("REACT_APP_ETH_CONTRACT_TOKEN_SYMBOL").default("Skey").asString();

  static readonly EthWaitBlocks = get("REACT_APP_ETH_WAIT_BLOCKS").default(1).asInt();
  static readonly HiddenContractAddresses = get("REACT_APP_HIDDEN_CONTRACT_ADDRESSES")
    .default("")
    .asString()
    .split(",")
    .map((addr) => addr.trim())
    .filter(Boolean);

  // Other ================================================================================
  static readonly DisableEslintPlugin = get("DISABLE_ESLINT_PLUGIN").default("true").asBool();
  static readonly i18nDebugMode = get("REACT_APP_I18N_DEBUG_MODE").default("false").asBool();
  static readonly GlobalTimeout = 1000 * 8; //[ms]
}
