/* eslint-disable no-console */

import { ContractEventPayload, Provider } from "ethers";
import { Staking, Staking__factory } from "staking-contract";

export type GuessTxHashArgs = {
  provider: Provider;
  sender: string;
  stakingAddress: string;
  value: bigint;
  expectedNonce: number;
};

export class GuessTxHash {
  private staking: Staking;
  private txHash: string | null = null;

  constructor(private args: GuessTxHashArgs) {
    this.staking = this.getStakingContract();

    this.staking.on(this.staking.getEvent("DepositIncreased")(), this.onDepositIncreased as any);
  }

  private onDepositIncreased = async (ev: ContractEventPayload) => {
    const [from, value] = ev.args;

    try {
      if (from !== this.args.sender) return;
      if (value !== this.args.value) return;

      const tx = await ev.getTransaction();

      if (tx.nonce !== this.args.expectedNonce) return;

      this.txHash = tx.hash;
    } catch (err) {
      console.error(err);
    }
  };

  async waitForDeposit() {
    const success = await this.waitFor(() => !!this.txHash, 200, 90000);
    if (!success) return null;

    return this.txHash;
  }

  destroy() {
    this.staking.removeListener(this.staking.getEvent("DepositIncreased")(), this.onDepositIncreased);
  }

  private getStakingContract() {
    return Staking__factory.connect(this.args.stakingAddress, this.args.provider);
  }

  private async waitFor(cond: () => boolean, interval: number, timeout: number) {
    const exp = Date.now() + timeout;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (cond()) return true;
      if (Date.now() > exp) return false;

      await this.delay(interval);
    }
  }

  private delay(timeout: number) {
    return new Promise<void>((resolve) => setTimeout(resolve, timeout));
  }
}
